import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useStripe } from '@stripe/react-stripe-js'
import { useMutation, useQuery } from '@apollo/client'
import { golfClubModel as graphqlGolfClubModel, order, user } from '@graphql'
import {
  AddressCard,
  BuyingOwnGolfClubModalState,
  GolfClub,
  GolfClubModel,
  GolfEquipment,
  GolfEquipmentModel,
  MutationResponse,
  PaymentCard,
  ProductPageItemType,
  ResponseType
} from '@types'
import { useAlert } from 'react-alert'
import { useGraphqlCheckout, useLocationWithState, useModal, useUrlSearchParams, useWindowSize } from '@hooks'

import { CheckoutLayout } from '@layouts'

import PurseIcon from '@images/purse.svg'
import BackArrow from '@images/back-arrow.svg'

interface CheckoutBuyNowProps {
  id: string
  type: 'golfClub' | 'equipment'
}

type CreateOrderMutationResponse = ResponseType<
  MutationResponse & { result: { stripeSessionId: string; id: string; accessUrl: string | null } }
>

const CheckoutBuyNow: React.FC<CheckoutBuyNowProps> = ({ id, type }) => {
  const stripe = useStripe()
  const alert = useAlert()
  const [selectedGolfClub, setSelectedGolfClub] = useState<GolfClub | GolfEquipment | undefined>()
  const { openModal } = useModal<{ ids: string[]; callback: () => void }>('DeleteSimilarGolfClubs')
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { data: userData } = useQuery(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const { openModal: openBuyingOwnGolfClubModal } = useModal<BuyingOwnGolfClubModalState>('BuyingOwnGolfClubModal')
  const { pathname } = useLocationWithState<{ type: string }>()
  const { golfType, golfTypeData, loading } = useGraphqlCheckout(id, type)
  const { getUrlSearchParam: getItemIdSearchParam } = useUrlSearchParams('itemId')

  const [createOrder, { data: orderData, loading: orderLoading }] = useMutation<CreateOrderMutationResponse>(
    order.CreateOrder
  )

  const currentUser = userData && userData.res
  const currentUserId = currentUser && currentUser.id
  const selectedGolfClubOwnerId = selectedGolfClub && selectedGolfClub.owner && selectedGolfClub.owner.id

  let golfClubsOnSale: GolfClub[] | GolfEquipment[]
  let golfModel: GolfEquipmentModel | GolfClubModel

  if (golfType && 'golfClubsOnSale' in golfType) {
    golfClubsOnSale = golfType.golfClubsOnSale
    golfModel = golfType.golfClubModel
  } else if (golfType && 'golfEquipmentsOnSale' in golfType) {
    golfClubsOnSale = golfType.golfEquipmentsOnSale
    golfModel = golfType.golfEquipmentModel
  }

  const usersSimilarPurchaseRequests = (golfType?.purchaseRequests || []).filter(purchaseRequest => {
    const isCurrentUsersPurchaseRequest = currentUserId === purchaseRequest?.user?.id
    return purchaseRequest.status === 'OPEN' && isCurrentUsersPurchaseRequest
  })

  const pathnameIndex = pathname.indexOf('buy-now')
  const checkoutSuccessValue = pathname.substr(pathnameIndex)

  useEffect(() => {
    const link = orderData?.res.result.accessUrl
    if (link) navigate(link)
  }, [orderData])

  useEffect(() => {
    const order = orderData?.res?.result
    const stripeSessionId = order?.stripeSessionId

    if (order) {
      if (stripeSessionId) {
        stripe?.redirectToCheckout({ sessionId: stripeSessionId })
      }

      if (order?.id) {
        navigate(`/checkout-success/?type=buy-now&orderId=${order.id}`, {
          state: { checkoutSuccessValue: checkoutSuccessValue }
        })
      }
    }
  }, [orderData])

  useEffect(() => {
    if (golfTypeData && golfTypeData.res && golfClubsOnSale?.length) {
      const specificItemId = getItemIdSearchParam()
      let specificItem = null
      // Then we know which item the user selected to buy now
      if (specificItemId) {
        golfClubsOnSale.forEach((club, index) => {
          if (club.id === specificItemId) {
            specificItem = golfClubsOnSale[index]
          }
        })
      }
      if (specificItem) {
        setSelectedGolfClub(specificItem)
      } else {
        setSelectedGolfClub(golfClubsOnSale[0])
      }
    }
  }, [golfTypeData])

  const handleCreateOrder = async (addressCard: AddressCard, userCard: PaymentCard, promoCodeId?: string) => {
    if (selectedGolfClub) {
      const addressCardId = addressCard.id
      const paymentCardId = userCard.id
      const golfClubId = selectedGolfClub.id
      let payload

      if (golfModel.__typename === 'GolfClubModel')
        payload = {
          addressCardId,
          paymentCardId,
          golfClubId,
          promoCodeId
        }
      else if (golfModel.__typename === 'GolfEquipmentModel')
        payload = {
          addressCardId,
          paymentCardId,
          golfEquipmentId: selectedGolfClub.id,
          promoCodeId
        }

      await createOrder({
        variables: { order: payload },
        refetchQueries: [{ query: graphqlGolfClubModel.FetchGolfClubModel, variables: { id: golfModel.id || '' } }]
      }).then(response => {
        const golfClubModelSlug = golfModel.slug
        const status = response.data?.res.successful

        if (status === false) {
          const errorDetail = response.data?.res?.messages[0].message
          alert.show(`Error! ${errorDetail}`, {
            type: 'error'
          })

          const isGolfClubPage = golfModel.__typename === 'GolfClubModel'
          const itemType: ProductPageItemType = isGolfClubPage ? 'GolfClubModel' : 'GolfEquipmentModel'

          navigate(`/product/${golfClubModelSlug}/${itemType}`)
        }
      })
    }
  }

  const handleConfirm = async (
    addressCard?: AddressCard,
    userCard?: PaymentCard,
    hasBeenModalOpened?: boolean,
    promoCodeId?: string
  ) => {
    if (!hasBeenModalOpened && usersSimilarPurchaseRequests.length) {
      const usersSimilarPurchaseRequestIds = usersSimilarPurchaseRequests.map(purchaseRequest => purchaseRequest.id)
      openModal({
        ids: usersSimilarPurchaseRequestIds || [],
        callback: () => handleConfirm(addressCard, userCard, true)
      })
      return
    }

    if (currentUserId === selectedGolfClubOwnerId) {
      return openBuyingOwnGolfClubModal({ slug: golfModel.slug, isSell: false })
    } else {
      await handleCreateOrder(addressCard!, userCard!, promoCodeId!)
    }
  }

  if (!golfType) return null
  return (
    <CheckoutLayout
      golfTypeData={golfTypeData}
      golfTypeLoading={loading}
      handleConfirm={handleConfirm}
      price={selectedGolfClub?.price}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PurseIcon />
          <span style={{ fontWeight: 600 }}>BUY NOW</span>
        </strong>
      }
      mutationLoading={orderLoading}
    />
  )
}

export default CheckoutBuyNow
